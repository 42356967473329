<template>
  <a-card :title="$t('title.spuList')">
    <div slot="extra" href="#">
      <a-button icon="plus" style="float:right" type="link" @click="handleSpuSelect()">{{ $t('button.add') }}
      </a-button>
      <a-button icon="plus" style="float:right" type="link" @click="handleSpuImport()">{{ $t('button.import') }}
      </a-button>
    </div>
    <a-table
      :columns="spuListColumns"
      :dataSource="spuList"
      :pagination="false"
      :bordered="true"
      :row-key="(record) => record.spuNo"
    >
      <div slot="listPicUrl" slot-scope="text,record">
        <img v-if="text" :src="text" style="max-height: 100px"/>
        <div v-else>
          <img v-if="record.picList && record.picList.length>0" :src="record.picList[0]" style="max-height: 100px"/>
        </div>
      </div>
      <template slot="action" slot-scope="text, record, index">
        <a-button icon="up" v-if="index>0" type="default" @click="handleUp(record,index)">{{ $t('button.moveUp') }}
        </a-button>
        <a-button icon="down" v-if="index <spuList.length-1" type="default" @click="handleDown(record,index)">
          {{ $t('button.moveDown') }}
        </a-button>
        <a-popconfirm :title="$t('msg.confirmDelete')" @confirm="() => onDeleteSpu(record.spuNo)">
          <a href="javascript:;">{{ $t('button.delete') }}</a>
        </a-popconfirm>
      </template>
    </a-table>
    <ImportSpu ref="importSpu" @handleSpuSelectedOk="handleSpuSelectedOk"></ImportSpu>
    <SelectSpu
      ref="selectSpu"
      :condition="condition"
      @handleSpuSelectedOk="handleSpuSelectedOk"></SelectSpu>
  </a-card>
</template>
<script>
import SelectSpu from '@/views/component/SelectSpu'
import ImportSpu from '@/views/marketing/components/ImportSpu'

export default {
  name: 'SpuListEdit',
  props: {
    spuList: {
      type: Array
    },
    condition: {
      type: Object
    }
  },
  components: { SelectSpu, ImportSpu },
  data () {
    return {
      selectedSpuList: [],
      spuListColumns: [
        {
          defaultTitle: 'SPU编码',
          title: this.$t('columnName.spuNo'),
          dataIndex: 'spuNo',
          key: 'spuNo',
          align: 'left'
        },
        {
          defaultTitle: 'SPU名称',
          title: this.$t('columnName.spuName'),
          dataIndex: 'spuName',
          key: 'spuName',
          align: 'left'
        },
        {
          defaultTitle: '列表图片URL',
          title: this.$t('columnName.listPicUrl'),
          dataIndex: 'listPicUrl',
          key: 'listPicUrl',
          align: 'left',
          scopedSlots: {
            customRender: 'listPicUrl'
          }
        },
        {
          defaultTitle: '操作',
          title: this.$t('button.delete'),
          dataIndex: 'action',
          key: 'action',
          align: 'left',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ]
    }
  },
  mounted () {
  },
  methods: {
    // spu
    handleSpuSelect (record) {
      this.selectedSpu = {}
      this.$refs.selectSpu.show(this.spuList)
    },
    handleSpuImport () {
      console.debug('handleSpuSelect', this.condition)
      this.$refs.importSpu.show()
    },
    // 确认spu弹框
    handleSpuSelectedOk (selectedSpu) {
      selectedSpu.forEach(item => {
        if(!this.spuList.filter(function(el) {return item.spuNo == el.spuNo}).length) {
          this.spuList.push(item)
        }
      })
      // 弹出编辑页面
      this.spuModalShow = false
    },
    onDeleteSpu (spuNo) {
      const list = this.spuList.filter(item => item.spuNo !== spuNo)
      this.spuList.splice(0, this.spuList.length)
      list.forEach(item => {
        this.spuList.push(item)
      })
    },
    handleUp (item, index) {
      this.spuList[index - 1] = this.spuList.splice(index, 1, this.spuList[index - 1])[0]
    },
    handleDown (item, index) {
      this.spuList[index + 1] = this.spuList.splice(index, 1, this.spuList[index + 1])[0]
    }

  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
</style>
