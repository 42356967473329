<template>
  <a-modal
    width="50%"
    :title="$t('page.spu_import')"
    :visible="importModalShow"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
      <ImportSpuList ref="importSpuList" :spu-list="spuList"></ImportSpuList>
    </a-card>
  </a-modal>
</template>

<script>
import ImportSpuList from '@/views/component/ImportSpuList'

export default {
  name: 'ImportSpu',
  components: { ImportSpuList },
  props: {},
  data () {
    return {
      importModalShow: false,
      spuList: [],
      importComment: '',
      marketplaceCode: ''
    }
  },
  mounted () {
  },
  methods: {
    show () {
      this.spuList = []
      this.importModalShow = true
      this.$nextTick(() => {
        console.debug('$nextTick')
        this.$refs.importSpuList.reset()
      })
    },
    handleCancel () {
      // 弹出编辑页面
      this.importModalShow = false
    },
    handleOk () {
      const that = this
      console.debug('handleOk', this.spuList)
      this.$emit('handleSpuSelectedOk', this.spuList)
      this.importModalShow = false
    }
  }
}
</script>
<style>
</style>
