<template>
  <a-modal
    width="50%"
    :title="$t('page.sku_import')"
    :visible="importModalShow"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
      <ImportSkuList ref="importSkuList" :sku-list.sync="skuList"></ImportSkuList>
    </a-card>
  </a-modal>
</template>

<script>
import ImportSkuList from '@/views/component/ImportSkuList'

export default {
  name: 'ImportSku',
  components: { ImportSkuList },
  props: {},
  data () {
    return {
      importModalShow: false,
      skuList: [],
      importComment: '',
      marketplaceCode: ''
    }
  },
  mounted () {
  },
  methods: {
    show () {
      this.skuList = []
      this.importModalShow = true
      this.$nextTick(() => {
        this.$refs.importSkuList.reset()
      })
    },
    handleCancel () {
      // 弹出编辑页面
      this.importModalShow = false
    },
    handleOk () {
      const that = this
      console.debug('handleOk', this.skuList)
      this.$emit('handleSkuSelectedOk', this.skuList)
      this.importModalShow = false
    }
  }
}
</script>
<style>
</style>
