<template>
  <a-modal
    width="80%"
    :confirm-loading="false"
    :title="$t('page.spu')"
    :visible="spuModalShow"
    @cancel="handleSpuEditCancel"
    @ok="
      spuModalShow = false
      $emit('handleSpuSelectedOk', selectedSpuList)
    "
  >
    <a-row :gutter="24">
      <a-col :md="8" :sm="24">
        <a-form-item
          :label="$t('field.spuNo')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="['spuNo', { rules: [{ len: true, message: this.$t('field.spuNo') + '长度需小于1000' }] }]"
            name="queryParam.spuNo"
            v-model="queryParam.spuNo"
            :placeholder="'请输入' + this.$t('field.spuNo')"
          />
        </a-form-item>
      </a-col>
      <a-col :md="8" :sm="24">
        <a-form-item
          :label="$t('field.spuName')"
          :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="['spuName', { rules: [{ len: true, message: this.$t('field.skuNo') + '长度需小于1000' }] }]"
            name="queryParam.spuName"
            v-model="queryParam.spuName"
            :placeholder="'请输入' + this.$t('field.spuName')"
          />
        </a-form-item>
      </a-col>
      <a-col :md="8" :sm="24">
        <span class="table-page-search-submitButtons">
          <a-button icon="search" type="primary" @click="searchSpu">{{ $t('button.search') }}</a-button>
        </span>
      </a-col>
    </a-row>
    <a-table
      :columns="spuListColumns"
      :dataSource="spuList"
      :pagination="pagination"
      :rowSelection="rowSelection"
      :bordered="true"
      :row-key="record => record.spuNo"
      :loading="loadingTable"
    >
      <div slot="listPicUrl" slot-scope="text, record">
        <img v-if="text" :src="text" style="max-height: 100px" />
        <div v-else>
          <img v-if="record.picList && record.picList.length > 0" :src="record.picList[0]" style="max-height: 100px" />
        </div>
      </div>
    </a-table>
  </a-modal>
</template>
<script>
import { searchSpu } from '@/api/goods/spu'

export default {
  name: 'SelectSpu',
  props: {
    condition: {
      type: Object
    }
  },
  computed: {
    rowSelection() {
      return {
        type: 'checkbox',
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSpuSelectChange,
        getCheckboxProps: record => ({
          props: {
            disabled: !!record.disabled,
            defaultChecked: !!record.disabled
          }
        })
      }
    }
  },
  data() {
    return {
      loadingTable: false,
      spuModalShow: false,
      selectedRowKeys: [],
      spuList: [],
      selectedSpuNoList: [],
      selectedSpuList: [],
      pagination: { 
        showSizeChanger: true, 
        showQuickJumper: true, 
        current: 1,
        pageSize: 10, 
        total: 0 ,
        pageSizeOptions: ['10', '20', '30', '40'],
        onShowSizeChange:(current, pageSize) => { //分页单位变更， 页码重置
          this.pagination.pageSize = pageSize;
          this.queryParam.pageNo = 1, this.queryParam.pageSize = pageSize;
          this.searchSpu()
        },
        onChange: (current, size) => {
          this.queryParam.pageNo = current, this.queryParam.pageSize = size;
          this.searchSpu()
        }
      },
      queryParam: { pageNo: 1, pageSize: 20, spuName: '', spuNo: '', keyword: '' },
      spuListColumns: [
        {
          defaultTitle: 'SPU编码',
          title: this.$t('columnName.spuNo'),
          dataIndex: 'spuNo',
          key: 'spuNo',
          align: 'left'
        },
        {
          defaultTitle: 'SPU名称',
          title: this.$t('columnName.spuName'),
          dataIndex: 'spuName',
          key: 'spuName',
          align: 'left'
        },
        {
          defaultTitle: '列表图片URL',
          title: this.$t('columnName.listPicUrl'),
          dataIndex: 'listPicUrl',
          key: 'listPicUrl',
          align: 'left',
          scopedSlots: {
            customRender: 'listPicUrl'
          }
        }
      ]
    }
  },
  mounted() {},
   watch: {
    spuModalShow(val) {
      if (!val) {
        //关闭弹窗清空数据
        this.selectedRowKeys = []
      }
    }
  },
  methods: {
    // 新增spu弹框
    show(arr = []) {
      // 弹出编辑页面
      this.spuModalShow = true
      this.searchSpu(arr)
      arr.forEach(el => {
        this.selectedRowKeys.push(el.spuNo)
      })
    },
    // 关闭spu弹框
    handleSpuEditCancel() {
      // 弹出编辑页面
      this.spuModalShow = false
    },
    onSpuSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedSpuList = selectedRows
      // this.$nextTick(() => {
      //   this.$forceUpdate()
      // })
    },
    searchSpu(arr = []) {
      let { spuNo, spuName, pageNo, pageSize } = this.queryParam;
      let param = { spuNo, spuName, pageNo, pageSize };
      if (this.condition) {
        param.categoryCode = this.condition.categoryCode
        param.brandCode = this.condition.brandCode
      }
      this.loadingTable = true
      this.spuList = []
      searchSpu(param)
        .then(res => {
          let list = res.data
          list.forEach(el => {
            el.disabled = false
            if (
              arr.length &&
              arr.filter(function(obj) {
                return el.spuNo == obj.spuNo
              }).length
            ) {
              el.disabled = true
            }
          })
          this.spuList = list
          this.pagination.total = res.totalCount, this.pagination.current = pageNo
          this.loadingTable = false
        })
        .catch(res => {
          this.$message.error(res.message)
          this.loadingTable = false
        })
    }
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
</style>
