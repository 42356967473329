<template>
  <!-- hidden PageHeaderWrapper title demo -->
  <a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
    <div class="table-operator" style="display:flex1">
      <span class="title">SKU一览1</span>
      <a-upload
        style="float:right"
        name="file"
        :multiple="true"
        :fileList="uploadFiles"
        accept=".xls,.xlsx"
        :beforeUpload="beforeUpload"
        :customRequest="uploadFilesCustomRequest"
      >
        <a-button>
          <a-icon type="upload" />
          {{ $t('button.readFromFile') }}
        </a-button>
      </a-upload>
    </div>
    <a-alert :message="importResultMsg" type="success" v-if="showImportResult" />
    <a-table
      :columns="skuColumns"
      :dataSource="list"
      :bordered="true"
      :scroll="{ x: true }"
      :pagination="pagination"
      :row-key="(record, index) => index"
      :loading="loadingTable"
    >
      <!-- <div slot="listPicUrl" slot-scope="text, record">
        <img v-if="text" :src="text" style="max-height: 100px" />
        <div v-else>
          <img v-if="record.picList && record.picList.length > 0" :src="record.picList[0]" style="max-height: 100px" />
        </div>
      </div> -->
      <template slot="action">
        <a-popconfirm :title="$t('msg.confirmDelete')" @confirm="() => onDeleteSku($index)">
          <a href="javascript:;">{{ $t('button.delete') }}</a>
        </a-popconfirm>
      </template>
    </a-table>
  </a-card>
</template>

<script>
import { importExcelReturnMaps } from '@/api/system/import'

export default {
  name: 'ImportSkuList',
  props: {
    skuList: {
      type: Array
    },
    fieldList: {
      type: Array
    }
  },
  data () {
    return {
      loadingTable: false,
      uploadFiles: [],
      importResultMsg: '',
      showImportResult: false,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: ['10', '20', '30', '40'],
        onShowSizeChange: (current, pageSize) => { // 分页单位变更， 页码重置
          this.pagination.pageSize = pageSize
          // this.queryParam.pageNo = 1, this.queryParam.pageSize = pageSize;
          // this.searchSku()
        },
        onChange: (current, size) => {
          this.pagination.current = current
          // this.queryParam.pageNo = current;
          // this.queryParam.pageSize = size;
          // this.searchSku()
        }
      },
      skuColumns: [],
      originalColumns: [
        {
          title: this.$t('field.skuNo'),
          dataIndex: 'skuNo',
          key: 'skuNo',
          align: 'left'
        },
        // {
        //   title: this.$t('field.skuName'),
        //   dataIndex: 'skuName',
        //   key: 'skuName',
        //   align: 'left'
        // },
        // {
        //   title: this.$t('field.spuNo'),
        //   dataIndex: 'spuNo',
        //   key: 'skuNo',
        //   align: 'left'
        // },
        // {
        //   title: this.$t('field.spuName'),
        //   dataIndex: 'spuName',
        //   key: 'spuName',
        //   align: 'left'
        // },
        // {
        //   title: this.$t('field.listPicUrl'),
        //   dataIndex: 'listPicUrl',
        //   key: 'listPicUrl',
        //   align: 'left',
        //   scopedSlots: {
        //     customRender: 'listPicUrl'
        //   }
        // },
        {
          defaultTitle: '操作',
          title: this.$t('button.delete'),
          dataIndex: 'action',
          key: 'action',
          align: 'left',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ]
    }
  },
  mounted () {},
  watch: {
    skuList (val) {
      console.log(val)
    }
  },
  computed: {
    list () {
      return this.skuList
    }
  },
  methods: {
    reset (fieldList) {
      this.pagination.total = 0
      this.importResultMsg = ''
      this.skuList.slice(0, this.skuList.length)
      this.showImportResult = false
      this.skuColumns = []
      this.originalColumns.forEach(column => {
        this.skuColumns.push(column)
      })
      if (fieldList) {
        fieldList.forEach(field => {
          this.skuColumns.push({
            title: this.$t('field.' + field),
            dataIndex: field,
            key: field,
            align: 'left'
          })
        })
      }
    },
    onDeleteSku (i) {
      this.skuList.splice(i, 1)
    },
    beforeUpload (file) {
      console.log(file.type, file.size)
      const isExcel =
        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel'
      if (!isExcel) {
        this.$message.error('请上传xls/xlsx格式文件')
      }
      const isLt10M = file.size / 1024 < 1024 * 1024 * 10 && file.size / 1024 > 1
      if (!isLt10M) {
        this.$message.error('文件大小应在1KB~10MB之间')
      }
      return isExcel && isLt10M
    },
    uploadFilesCustomRequest (data) {
      this.importLoading = true
      const formData = new FormData()
      if (data.file != null) {
        formData.append('file', data.file)
      }

      this.$emit('update:sku-list', [])
      this.loadingTable = true
      importExcelReturnMaps(formData)
        .then(res => {
          if (res.success) {
            this.$message.success('导入成功')
            this.$emit('update:sku-list', res.data || [])
            this.pagination.total = res.data.length
            // this.pagination.total = 0
            this.pagination.current = 1
            this.showImportResult = true
            this.importResultMsg = '导入成功:' + res.data.length + '条'
            this.loadingTable = false
          } else {
            this.$message.error('导入失败,原因：' + res.msg)
          }
        })
        .catch(res => {
          console.log('导入失败', res)
          this.$message.error('导入失败')
          this.loadingTable = false
        })
    }
  }
}
</script>
<style>
</style>
