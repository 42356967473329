<template>
  <a-form-item
    :label="$t('field.siteList')"
    :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
    :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
  >
    <a-select
      mode="multiple"
      v-model="selectedSiteCodeList"
      @change="onSiteListChanged">
      <a-select-option
        v-for="(item, index) in codeList.siteList"
        :key="index"
        :siteCode="item.siteCode"
        :siteName="item.siteName"
        :site="item"
        :siteId="item.siteId"
        :marketplaceName="item.marketplaceName"
        :marketplaceCode="item.marketplaceCode"
        :marketplaceId="item.marketplaceId"
        :country="item.country"
        :value="item.siteCode"
      >[{{ item.country }}-{{ item.marketplaceCode }}-{{ item.marketplaceName }}] {{ item.siteCode
      }}-{{ item.siteName }}
      </a-select-option>
    </a-select>
  </a-form-item>
</template>
<script>

import { listSite } from '@/api/system/site'

export default {
  name: 'SelectSiteList',
  props: {
    siteCodeList: {
      type: Array
    },
    siteList: {
      type: Array
    },
    search: {
      type: String
    }
  },
  watch: {
    siteCodeList (siteCodeList) {
      this.selectedSiteCodeList = siteCodeList
    },
    search (marketplaceCode) {
      listSite({ marketplaceCode: marketplaceCode }).then(res => {
        this.codeList.siteList = res.data
      })
    }
  },
  data () {
    return {
      selectedSiteCodeList: [],
      codeList: {
        siteList: []
      }
    }
  },
  mounted () {
    listSite({ marketplaceCode: this.search }).then(res => {
      this.codeList.siteList = res.data
    })
  },
  methods: {
    onSiteListChanged (value, option) {
      this.siteList.splice(0, this.siteList.length)
      option.map((item, index, array) => {
        this.siteList.push(item.data.attrs.site)
      })
      this.$emit('siteDataChange', this.siteCodeList)
    }
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
</style>
