<template>
  <a-modal
    width="80%"
    :confirm-loading="false"
    :title="$t('page.sku')"
    :visible="skuModalShow"
    @cancel="handleSkuEditCancel"
    @ok="
      skuModalShow = false
      $emit('handleSkuSelectedOk', selectedSkuList)
    "
  >
    <a-row :gutter="24">
      <a-col :md="8" :sm="24">
        <a-form-item
          :label="$t('field.keyword')"
          :labelCol="{ lg: { span: 8 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 17 } }"
        >
          <a-input v-model="queryParam.keyword" :placeholder="'请输入' + this.$t('field.keyword')" />
        </a-form-item>
      </a-col>
      <a-col :md="8" :sm="24">
        <a-form-item
          :label="$t('field.spuNo')"
          :labelCol="{ lg: { span: 8 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'queryParam.spuNo',
              { rules: [{ len: true, message: this.$t('field.spuNo') + '长度需小于1000' }] }
            ]"
            :placeholder="'请输入' + this.$t('field.spuNo')"
          />
        </a-form-item>
      </a-col>
      <a-col :md="8" :sm="24">
        <a-form-item
          :label="$t('field.skuNo')"
          :labelCol="{ lg: { span: 8 }, sm: { span: 7 } }"
          :wrapperCol="{ lg: { span: 16 }, sm: { span: 17 } }"
        >
          <a-input
            v-decorator="[
              'queryParam.skuNo',
              { rules: [{ len: true, message: this.$t('field.skuNo') + '长度需小于1000' }] }
            ]"
            name="queryParam.skuNo"
            :placeholder="'请输入' + this.$t('field.skuNo')"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :md="8" :gutter="24">
      <a-col :md="24" :sm="24" style="text-align: center; margin-bottom: 20px">
        <span class="table-page-search-submitButtons">
          <a-button icon="search" type="primary" @click="searchSku">{{ $t('button.search') }}</a-button>
        </span>
      </a-col>
    </a-row>
    <a-table
      :columns="skuListColumns"
      :data-source="skuList"
      :pagination="pagination"
      :row-selection="rowSelection"
      :row-key="record => record.skuNo"
      :bordered="true"
      :loading="loadingTable"
    >
      <div slot="listPicUrl" slot-scope="text, record">
        <img v-if="text" :src="text" style="max-height: 100px" />
        <div v-else>
          <img v-if="record.picList && record.picList.length > 0" :src="record.picList[0]" style="max-height: 100px" />
        </div>
      </div>
    </a-table>
  </a-modal>
</template>
<script>
import { searchSku } from '@/api/goods/sku'

export default {
  name: 'SelectSku',
  props: {
    condition: {
      type: Object
    }
  },
  computed: {
    rowSelection() {
      return {
        type: 'checkbox',
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSkuSelectChange,
        getCheckboxProps: record => ({
          props: {
            disabled: !!record.disabled,
            defaultChecked: !!record.disabled
          }
        })
      }
    }
  },
  data() {
    return {
      loadingTable: false,
      skuModalShow: false,
      skuNo: null,
      skuList: [],
      selectedRowKeys: [],
      selectedSkuList: [],
      pagination: { 
        showSizeChanger: true, 
        showQuickJumper: true, 
        current: 1,
        pageSize: 10, 
        total: 0 ,
        pageSizeOptions: ['10', '20', '30', '40'],
        onShowSizeChange:(current, pageSize) => { //分页单位变更， 页码重置
          this.pagination.pageSize = pageSize;
          this.queryParam.pageNo = 1, this.queryParam.pageSize = pageSize;
          this.searchSku()
        },
        onChange: (current, size) => {
          this.queryParam.pageNo = current, this.queryParam.pageSize = size;
          this.searchSku()
        }
      },

      queryParam: { pageNo: 1, pageSize: 10 },
      skuListColumns: [
        {
          defaultTitle: 'SKU编号',
          title: this.$t('columnName.skuNo'),
          dataIndex: 'skuNo',
          key: 'skuNo',
          align: 'left'
        },
        {
          defaultTitle: 'SKU名称',
          title: this.$t('columnName.skuName'),
          dataIndex: 'skuName',
          key: 'skuName',
          align: 'left',
          scopedSlots: {
            customRender: 'skuName'
          }
        },
        {
          defaultTitle: '列表图片URL',
          title: this.$t('columnName.listPicUrl'),
          dataIndex: 'listPicUrl',
          key: 'listPicUrl',
          align: 'left',
          scopedSlots: {
            customRender: 'listPicUrl'
          }
        }
      ]
    }
  },
  mounted() {},
  watch: {
    skuModalShow(val) {
      if (!val) {
        //关闭弹窗清空数据
        this.selectedRowKeys = []
      }
    }
  },
  methods: {
    // 新增sku弹框
    show(arr = []) {
      // 弹出编辑页面
      this.skuModalShow = true
      console.log('show =>', arr)
      this.searchSku(arr)
      arr.forEach(el => {
        this.selectedRowKeys.push(el.skuNo)
      })
    },
    // 关闭sku弹框
    handleSkuEditCancel() {
      // 弹出编辑页面
      this.skuModalShow = false
    },
    onSkuSelectChange(selectedRowKeys, selectedRows) {
      // console.debug('onSkuSelectChange', selectedRowKeys, selectedRows)
      this.selectedRowKeys = selectedRowKeys
      this.selectedSkuList = selectedRows
      // this.$nextTick(() => {
      //   this.$forceUpdate()
      // })
    },
    searchSku(arr = []) {
      console.log(this.pagination)
      let { keyword, spuNo, skuNo, pageNo, pageSize } = this.queryParam;
      let param = { keyword, spuNo, skuNo, isFirstSku: '1', pageNo, pageSize }
      if (this.condition) {
        param.categoryCode = this.condition.categoryCode
        param.brandCode = this.condition.brandCode
      }
      this.loadingTable = true
      this.skuList = []
      searchSku(param)
        .then(res => {
          let list = res.data
          list.forEach(el => {
            el.disabled = false
            if (
              arr.length &&
              arr.filter(function(obj) {
                return el.skuNo == obj.skuNo
              }).length
            ) {
              el.disabled = true
            }
          })
          console.log('selectedRowKeys =>', this.selectedRowKeys)
          this.skuList = list
          this.pagination.total = res.totalCount, this.pagination.current = pageNo
          this.loadingTable = false
        })
        .catch(res => {
          console.log(res)
          this.$message.error(res.message)
          this.loadingTable = false
        })
    }
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
</style>
