<template>
  <a-form-item
    has-feedback=""
    :label="$t('field.marketplace')"
    :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
    :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
  >
    <a-select
      mode="default"
      v-model="data.marketplaceCode"
      :disabled="selectDisabled"
      v-decorator="[
        'marketplaceCode',
        {
          getValueFromEvent:onMarketplaceListChanged,
          rules: [
            { required: true, message: '请输入' + this.$t('field.marketplaceCode') },
          ],
        },
      ]">
      <a-select-option
        v-for="(item, index) in codeList.marketplaceList"
        :key="index"
        :marketplaceName="item.marketplaceName"
        :marketplaceCode="item.marketplaceCode"
        :marketplaceId="item.marketplaceId"
        :marketplace="item"
        :country="item.country"
        :value="item.marketplaceCode"
      >[{{ item.country }}]{{ item.marketplaceCode }}-{{ item.marketplaceName }}
      </a-select-option>
    </a-select>
  </a-form-item>
</template>
<script>

import { listMarketplace } from '@/api/system/marketplace'

export default {
  name: 'SelectMarketplace',
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {
      selectDisabled: false,
      codeList: {
        marketplaceList: []
      }
    }
  },
  mounted () {
    console.log('mounted')
    listMarketplace({}).then(res => {
      this.codeList.marketplaceList = res.data
    })
    // 编辑模式中 市场不能改 marketplaceCode
    if (this.$route.query.type === 'edit') {
      this.selectDisabled = true
    }
  },
  methods: {
    onMarketplaceListChanged (value, option) {
      this.data.marketplaceCode = option.data.attrs.marketplaceCode
      this.data.marketplaceName = option.data.attrs.marketplaceName
      this.data.marketplaceId = option.data.attrs.marketplaceId
      this.data.country = option.data.attrs.country
      this.$emit('onMarketplaceChanged', option.data.attrs)
      return value
    }
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
</style>
