import request from '@/utils/request'

const baseUrl = '/api'

export function importExcelReturnMaps (parameter) {
  return request({
    url: baseUrl + '/system/importExcelReturnMaps',
    method: 'post',
    data: parameter
  }).then(res => {
    console.log('importExcelReturnMaps->response', res)
    return res
  })
}
