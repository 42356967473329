import request from '@/utils/request'

const baseUrl = '/api'

export function saveSkuData (parameter) {
  let url = baseUrl + '/goods/sku/save'
  if (!parameter.skuId) {
    url = baseUrl + '/goods/sku/create'
  }
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function putOnSku (parameter) {
  let url = baseUrl + '/goods/sku/putOn'
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function getPuOnData (parameter) {
  let url = baseUrl + '/goods/sku/getPutOn'
  return request({
    url: url,
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function searchSku (parameter) {
  return request({
    url: baseUrl + '/goods/sku/search',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list,
      pageNo: res.data.pageInfo.pageNo ? res.data.pageInfo.pageNo : 1,
      totalCount: res.data.pageInfo.totalCount
    }
  })
}

export function listSku (parameter) {
  return request({
    url: baseUrl + '/goods/sku/list',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data
    }
  })
}

export function getSkuData (parameter) {
  return request({
    url: baseUrl + '/goods/sku/get',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function enableSku (parameter) {
  return request({
    url: baseUrl + '/goods/sku/enable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function disableSku (parameter) {
  return request({
    url: baseUrl + '/goods/sku/disable',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function approvalSku (parameter) {
  return request({
    url: baseUrl + '/goods/sku/approval',
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function getSkuDetail (parameter) {
  return request({
    url: baseUrl + '/goods/sku/get', // getDetail
    method: 'post',
    data: parameter
  }).then(res => {
    return res.data
  })
}

export function deleteSku (parameter) {
  return request({
    url: baseUrl + '/goods/sku/delete',
    method: 'post',
    data: parameter
  }).then(res => {
    return {
      data: res.data.list
    }
  })
}
