<template>
  <div>
    <a-card :title="$t('title.skuList')">
      <div slot="extra" href="#">
        <a-button icon="plus" style="float:right" type="link" @click="handleSkuSelect()">{{ $t('button.add') }}
        </a-button>
        <a-button icon="plus" style="float:right" type="link" @click="handleSkuImport()">{{ $t('button.import') }}
        </a-button>
      </div>
      <a-table
        :columns="skuListColumns"
        :dataSource="skuList"
        :pagination="false"
        :bordered="true"
        :row-key="(record) => record.skuNo"
      >
        <div slot="listPicUrl" slot-scope="text,record">
          <img v-if="text" :src="text" style="max-height: 100px"/>
          <div v-else>
            <img v-if="record.picList && record.picList.length>0" :src="record.picList[0]" style="max-height: 100px"/>
          </div>
        </div>
        <template slot="action" slot-scope="text, record, index">
          <a-button icon="up" v-if="index>0" type="default" @click="handleUp(record,index)">{{ $t('button.moveUp') }}
          </a-button>
          <a-button icon="down" v-if="index <skuList.length-1" type="default" @click="handleDown(record,index)">{{ $t('button.moveDown') }}
          </a-button>
          <a-popconfirm :title="$t('msg.confirmDelete')" @confirm="() => onDeleteSku(record.skuNo)">
            <a href="javascript:;">{{ $t('button.delete') }}</a>
          </a-popconfirm>
        </template>
      </a-table>
      <ImportSku ref="importSku" @handleSkuSelectedOk="handleSkuSelectedOk"></ImportSku>
      <SelectSku
        ref="selectSku"
        :condition="condition"
        @handleSkuSelectedOk="handleSkuSelectedOk"></SelectSku>
    </a-card>
  </div>
</template>
<script>
import SelectSku from '@/views/component/SelectSku'
import ImportSku from '@/views/marketing/components/ImportSku'

export default {
  name: 'SkuListEdit',
  props: {
    skuList: {
      type: Array
    },
    condition: {
      type: Object
    }
  },
  components: { ImportSku, SelectSku },
  data () {
    return {
      skuListColumns: [
        {
          defaultTitle: 'SKU编号',
          title: this.$t('columnName.skuNo'),
          dataIndex: 'skuNo',
          key: 'skuNo',
          align: 'left'
        },
        {
          defaultTitle: 'SKU名称',
          title: this.$t('columnName.skuName'),
          dataIndex: 'skuName',
          key: 'skuName',
          align: 'left',
          scopedSlots: {
            customRender: 'skuName'
          }
        },
        {
          defaultTitle: '列表图片URL',
          title: this.$t('columnName.listPicUrl'),
          dataIndex: 'listPicUrl',
          key: 'listPicUrl',
          align: 'left',
          scopedSlots: {
            customRender: 'listPicUrl'
          }
        },
        {
          defaultTitle: '操作',
          title: this.$t('button.delete'),
          dataIndex: 'action',
          key: 'action',
          align: 'left',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ]
    }
  },
  mounted () {
  },
  methods: {
    // 新增sku弹框
    handleSkuSelect () {
      console.debug('handleSkuSelect', this.condition)
      this.$refs.selectSku.show(this.skuList)
    },
    handleSkuImport () {
      console.debug('handleSkuSelect', this.condition)
      this.$refs.importSku.show()
    },
    // 确认sku弹框
    handleSkuSelectedOk (selectedSku) {
      selectedSku.forEach(item => {
        if(!this.skuList.filter(function(el) {return item.skuNo == el.skuNo}).length) {
          this.skuList.push(item)
        }
      })
      // 弹出编辑页面
      this.skuModalShow = false
      console.log('handleSkuSelectedOk', selectedSku, this.skuList)
    },
    onDeleteSku (skuNo) {
      const list = this.skuList.filter(item => item.skuNo !== skuNo)
      this.skuList.splice(0, this.skuList.length)
      list.forEach(item => {
        this.skuList.push(item)
      })
    },
    handleUp (item, index) {
      this.skuList[index - 1] = this.skuList.splice(index, 1, this.skuList[index - 1])[0]
    },
    handleDown (item, index) {
      this.skuList[index + 1] = this.skuList.splice(index, 1, this.skuList[index + 1])[0]
    }
  }
}
</script>
<style>
.ant-select {
  width: 100%;
}
</style>
